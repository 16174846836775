<template>
    <div class="add-new-empoyee">
        <title-bar
            v-if="customer"
            submitBtn
            :submitBtnTitle="$t('messages.customerUpdate')"
            :title="customer.customer"
            @submitPressed="submitPressed"
        />
        <b-row>
            <b-col sm="3">
                <b-card>
                    <b-list-group id="customer-list">
                        <b-list-group-item
                            v-for="(item, index) in customerList"
                            :key="index"
                            :href="$helper.getEditUrl('customers', item.id)"
                            :id="item.active ? 'active-item' : ''"
                            :active="item.active"
                            :scroll="item.active ? scrollToName(index) : ''"
                            class="d-flex justify-content-between align-items-center"
                        >
                            {{ item.customer }}
                            <div>
                                <b-badge :variant="item.enabled ? 'success' : 'danger'">
                                    {{ item.enabled ? "active" : "inactive" }}
                                </b-badge>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
            <b-col sm="9">
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card content-class="mt-3" ref="tabs">
                        <b-tab key="overview" v-if="$helper.userAdditionalCapability('customer_overview')" :title="$t('forms.overview')" active>
                            <customer-form
                                v-if="customer"
                                :customerObject="customer"
                                :action="action"
                                @clearAction="action = null"
                            />
                        </b-tab>
                        <b-tab key="ops" v-if="$helper.userAdditionalCapability('customer_ops_conditions')" :title="$t('forms.opsConditions')">
                            <ops-form
                                v-if="customer"
                                :customerObject="customer"
                                :action="action"
                                @clearAction="action = null"
                            />
                        </b-tab>
                        <b-tab v-if="$helper.userAdditionalCapability('customer_ops_files')" :title="$t('forms.opsFiles')">
                            <ops-files
                                v-if="customer"
                                :customer="customer"
                            />
                        </b-tab>
                        <b-tab v-if="$helper.userAdditionalCapability('customer_contract')" :title="$t('forms.contract')">
                            <files-form
                                :resource="$Users"
                                :resource-id="$route.params.id"
                                :file-tags="['contract']"
                                message="customerUpdated"
                            />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CustomerForm from "./components/CustomerForm.vue";
import FilesForm from "@/components/Files/FilesForm.vue";
import OpsForm from "./components/OpsForm.vue";
import OpsFiles from "./components/OpsFiles.vue";

export default {
    components: {
        TitleBar,
        CustomerForm,
        FilesForm,
        OpsForm,
        OpsFiles
    },
    data() {
        return {
            tabIndex: 0,
            customer: null,
            action: null,
            customerList: null,
        };
    },
    created() {
        this.load();
        this.loadList();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Users.getResource({id}).then((response) => {
                this.customer = response.data;
                if (this.customer.customerData.length === 0) {
                    this.customer.customerData = {};
                }
            });
        },
        loadList() {
            const params = {
                role: "ROLE_CUSTOMER",
                "order[enabled]": "desc",
                "order[customer]": "asc",
                'pagination': false
            };
            this.$Users.getCollection({params}).then((response) => {
                this.customerList = response.data["hydra:member"];
                this.customerList.forEach((element) => {
                    if (element.id === this.$route.params.id) {
                        element.active = true;
                    }
                });
            });
        },
        scrollToName(index) {
            document.getElementById("customer-list").scrollTop = index * 40;
        },
        submitPressed() {
            if (this.$refs['tabs'].tabs[this.tabIndex].tab.key === 'ops') {
                this.action = "update_ops";
            } else {
                this.action = "update";
            }
        },
    },
};
</script>
